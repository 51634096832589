import styled from '@emotion/styled';
import { PrimerBannerProps } from './Alert';

const palette = {
  backgroundColors: {
    info: '#dbedff',
    warn: '#fffbdd',
    error: '#ffe3e6',
    success: '#dcffe4',
  },
  borderColor: {
    info: 'rgba(4,66,137,0.2)',
    warn: 'rgba(176,136,0,0.2)',
    error: 'rgba(158,28,35,0.2)',
    success: 'rgba(23,111,44,0.2)',
  },
  octiconColor: {
    info: 'rgba(4,66,137,0.6)',
    warn: '#b08800',
    error: 'rgba(158,28,35,0.6)',
    success: 'rgba(23,111,44,0.8)',
  },
};

export const FlashContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
`;

export const Flash = styled.div(
  ({ variant = 'info' }: { variant: PrimerBannerProps['variant'] }) => ({
    position: 'relative',
    padding: '20px 16px',
    margin: '12px',
    color: '#24292e',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '8px',
    backgroundColor: palette.backgroundColors[variant],
    borderColor: palette.borderColor[variant],
    textAlign: 'center',
    '.octicon': {
      marginRight: '12px',
      color: palette.octiconColor[variant],
    },
    p: {
      fontFamily: 'inherit',
      fontSize: '14px',
      margin: 0,
      a: {
        transition: 'border-bottom 0.25s ease-in-out',
        borderBottom: '2px solid rgba(59, 57, 52, 0.4)',
        textDecoration: 'none',
        color: 'inherit',

        '&:hover': {
          borderBottom: '2px solid rgba(59, 57, 52, 0.8)',
        },
      },
    },
  })
);
