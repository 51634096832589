import React from 'react';
import { useApi } from '../../hooks';
import _ from '../../utils';
import Alert from '../core/Alert';

export function MessagesInAlert() {
  const { isLoading, hasErrors, data } = useApi('facts');

  if (isLoading || hasErrors) {
    return null;
  }

  return <Alert message={_.randomize(data.facts)} isMarkdown />;
}
