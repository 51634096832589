import React from 'react';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import { FlashContainer, Flash } from './Alert.styles';

export interface PrimerBannerProps {
  message: string;
  variant?: 'info' | 'success' | 'warn' | 'error';
  isMarkdown?: boolean;
}

/**
 * Alert inspired from GitHub's Primer design system.
 *
 * @param {PrimerBannerProps} props Properties accepted by Alert.
 *
 * Ref: {@link https://primer.style/css/components/alerts}
 */
export default function Alert({
  variant = 'info',
  message,
  isMarkdown,
}: PrimerBannerProps) {
  const className = 'primer-alert';
  const Message = isMarkdown ? (
    <Flash
      className={className}
      variant={variant}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked(message)) }}
    />
  ) : (
    <Flash className={className} variant={variant}>
      {message}
    </Flash>
  );

  return (
    <FlashContainer className="primer-alert-container">
      {Message}
    </FlashContainer>
  );
}
