import React, { Fragment } from 'react';

import styles from './NoPosts.module.scss';

/**
 * This will display an illustration when
 * there are no blog posts to display.
 *
 * @param {Object} props - Properties.
 * @param {String} theme - Current body theme.
 */
class NoPostsIllustration extends React.Component {
  /* constructor(props) {
        super(props)
        this.state = {
            currentTheme: '',
        }
    }

    handleThemeChange = () => {
        this.setState({
            currentTheme: document.body.classList.value,
        })
    }

    componentDidMount() {
        this.handleThemeChange()

        // https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
        let target = document.body
        let config = { attributes: true }
        this.observer = new MutationObserver(this.handleThemeChange)
        this.observer.observe(target, config)
    }

    componentWillUnmount() {
        this.observer.disconnect()
    } */

  render() {
    return (
      <React.Fragment>
        <div className={styles.no_posts}>
          <NoBlogPostsSepia />
          <h2>{this.props.message || 'No posts yet!'}</h2>
        </div>
      </React.Fragment>
    );
  }
}

/**
 * @see SVG sourced from {@link https://www.manypixels.co/gallery/ ManyPixels}.
 * @see SVG to JSX conversion by {@link https://transform.tools/svg-to-jsx Transform}.
 */
const NoBlogPostsSepia = () => (
  <Fragment>
    <svg id="no-blog-posts" data-name="Layer 1" viewBox="0 0 407.22 332.41">
      <defs>
        <linearGradient
          id="prefix__a"
          x1={154.44}
          y1={220.63}
          x2={204.69}
          y2={239.78}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} />
          <stop offset={0.12} stopOpacity={0.75} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__b"
          x1={128.92}
          y1={276.06}
          x2={113.67}
          y2={184.09}
          xlinkHref="#prefix__a"
        />
        <linearGradient
          id="prefix__c"
          x1={134.36}
          y1={270.94}
          x2={159.48}
          y2={214.41}
          xlinkHref="#prefix__a"
        />
        <linearGradient
          id="prefix__d"
          x1={262.98}
          y1={213.1}
          x2={219.92}
          y2={357.78}
          xlinkHref="#prefix__a"
        />
        <linearGradient
          id="prefix__e"
          x1={85.31}
          y1={219.96}
          x2={127.03}
          y2={417.81}
          xlinkHref="#prefix__a"
        />
        <linearGradient
          id="prefix__f"
          x1={264.92}
          y1={268.06}
          x2={265.51}
          y2={240.84}
          gradientTransform="rotate(180 265.195 255.4)"
          xlinkHref="#prefix__a"
        />
        <linearGradient
          id="prefix__g"
          x1={307.69}
          y1={284.87}
          x2={311.73}
          y2={182.58}
          xlinkHref="#prefix__a"
        />
      </defs>
      <title>{'Web Developer'}</title>
      <rect
        x={36.21}
        y={12.31}
        width={370.4}
        height={237.25}
        rx={8.95}
        fill="#f5eddd"
        opacity={0.48}
        style={{
          isolation: 'isolate',
        }}
      />
      <path
        d="M399.28 266.88c-.27 7.77-2.05 15.68-6.4 22.12-5.48 8.11-14.38 13.13-23 17.85l-24.44 13.45c-10.71 5.9-22 11.94-34.21 12.11-8.64.13-17-2.72-25.31-5.11-38.81-11.2-79.69-12.77-119.81-9.94-20.55 1.45-41 4-61.36 7.07-16.29 2.42-35.84 10.47-50.53.94-16-10.4-25.82-27.24-34.86-43.59-7.87-14.19-15.85-28.73-18.45-44.74-4-24.74 5.58-50.15 20.57-70.25a161.15 161.15 0 0114.73-17v90.83a9 9 0 008.95 9h352.49a70.17 70.17 0 011.63 17.26zM267.58 12.32h-94.25a56.62 56.62 0 0111.48-6.76c7.45-3.25 15.57-4.7 23.67-5.28 20.38-1.47 40.82 3.03 59.1 12.04z"
        fill="#f5eddd"
        opacity={0.18}
        style={{
          isolation: 'isolate',
        }}
      />
      <path
        d="M181.34 219.7a14.62 14.62 0 003.27 3.16 15.28 15.28 0 006 1.78l31.92 4.81a1.85 1.85 0 01.89.3 1.82 1.82 0 01.47 1.44 56.17 56.17 0 01-2.39 17.66 2.44 2.44 0 01-.65 1.24 2.56 2.56 0 01-1.46.44c-10.74.91-21.53.62-32.3.33a22 22 0 01-5.38-.59c-3-.84-5.45-2.89-7.71-5-4.85-4.51-24.48-22.64-25.67-25.1a67.19 67.19 0 01-3.8-10.92c-1-3.6 6.31-4.05 6.16-7.79a24.17 24.17 0 01.92-7.66 13.08 13.08 0 013.2-5.92c1.1-1-.6-7.78.92-7.86 1.76 6 9.72 18 13.18 23.14a193.68 193.68 0 0012.43 16.54z"
        fill="#f5eddd"
      />
      <path
        d="M186.23 232.48a14.62 14.62 0 003.27 3.16 15.28 15.28 0 006 1.78l31.92 4.81a1.85 1.85 0 01.89.3 1.82 1.82 0 01.47 1.44 56.17 56.17 0 01-2.39 17.66 2.44 2.44 0 01-.65 1.24 2.56 2.56 0 01-1.46.44c-10.74.91-21.53.62-32.3.33a22 22 0 01-5.38-.59c-3-.84-5.45-2.89-7.71-5-4.85-4.51-24.48-22.64-25.67-25.1a67.19 67.19 0 01-3.8-10.92c-1-3.6 6.31-4.05 6.16-7.79a24.17 24.17 0 01.92-7.66 13.08 13.08 0 013.2-5.92c1.1-1-.6-7.78.92-7.86 1.76 6 9.72 18 13.18 23.14a193.68 193.68 0 0012.43 16.54z"
        transform="translate(-4.89 -12.78)"
        fill="url(#prefix__a)"
      />
      <path
        d="M100.57 169.04a58.54 58.54 0 00-6 6.2c-4.42 5.43-7.36 11.89-10.1 18.33-7.29 17.14-13.64 35.47-11.75 54a7 7 0 00.88 3.18 6.19 6.19 0 003.43 2.21c3.51 1.12 7.26 1.17 11 1.2 14.41.13 28.84.26 43.24-.44 2.23-.11 4.59-.28 6.44-1.54a10.44 10.44 0 003.14-3.93c3.87-7.28 5-15.7 8-23.36 4-10 15-19.09 12.68-29.63-9.92-44.75-56.42-30.35-60.96-26.22z"
        fill="#f5eddd"
      />
      <path
        d="M105.46 181.82a58.54 58.54 0 00-6 6.2c-4.42 5.43-7.36 11.89-10.1 18.33-7.29 17.14-13.64 35.47-11.75 54a7 7 0 00.88 3.18 6.19 6.19 0 003.43 2.21c3.51 1.12 7.26 1.17 11 1.2 14.41.13 28.84.26 43.24-.44 2.23-.11 4.59-.28 6.44-1.54a10.44 10.44 0 003.14-3.93c3.87-7.28 5-15.7 8-23.36 4-10 15-19.09 12.68-29.63-9.92-44.75-56.42-30.35-60.96-26.22z"
        transform="translate(-4.89 -12.78)"
        fill="url(#prefix__b)"
      />
      <path
        d="M165.69 131.97c-1.69 8.21-3.46 16.64-8 23.71a18.36 18.36 0 01-6.93 6.82c-2.93 1.47-6.65 1.67-9.39-.13-1.37-.89-2.87-2.29-4.36-1.63-1.08.49-1.43 1.83-1.58 3a30.2 30.2 0 00.12 8.65 7.31 7.31 0 01.14 2.46 4.06 4.06 0 01-3.25 3 7.75 7.75 0 01-4.53-.71 14.64 14.64 0 01-8.44-10.82 35.06 35.06 0 01-.3-4.8c-.19-7.12-1.32-14.21-1.09-21.32s2-14.51 6.83-19.76a31.12 31.12 0 019.76-6.76c5.45-2.6 11.53-4.43 17.49-3.48a16.62 16.62 0 0111.57 7.72c3.12 4.79 3.13 8.36 1.96 14.05z"
        fill="#ffdad2"
      />
      <path
        d="M180.58 113.88a16.55 16.55 0 00-8.75-16.66c-2.65-1.32-5.6-1.88-8.46-2.64-6.7-1.8-13.13-4.78-20-5.42s-14.77 1.77-17.83 8c-.56 1.14-1.11 2.52-2.34 2.82a5.13 5.13 0 01-2-.18c-4.59-.81-8.72 3.11-10.79 7.28-3.34 6.75-3.51 14.62-2.78 22.11.67 6.93 2.44 14.42 8 18.64.55.42 1.36.8 1.89.35a1.58 1.58 0 00.43-.93 30.4 30.4 0 00.62-8c-.11-2-.43-4.12.09-6.09s2.19-3.84 4.23-3.78c1.89 0 3.41 1.76 3.88 3.6a13.28 13.28 0 01-.21 5.64c.3.75 1.35-.15 1.53-.93 1.38-6.28 1.69-13.31 6.05-18a7.73 7.73 0 011.88 3.27 5 5 0 015.15-1.28 12.24 12.24 0 013.44 2.25c10.62 8.29 33.74 5.83 35.97-10.05z"
        fill="#3f3d56"
      />
      <path
        d="M103.63 179.28c-3.36 2.44-5 6.73-5.1 10.87s4.28 30.41 8.41 37.87c3.2 5.8 9.87 20.94 20 25.12 3 1.24 47.35.72 67.93-1.51-.13-4.87-.37-9.74-.69-14.61a2.77 2.77 0 00-.55-1.79c-.52-.54-1.26-4.07-2-4.07-12 .05-24.1 3.72-36.1 4a21.12 21.12 0 01-5.21-.31 15 15 0 01-4-1.64c-9.26-5.24-14.38-15.53-18-25.54-2.77-7.72-5-15.71-9-22.92-1.42-2.57-2.69-5.07-5.49-6.2-3.29-1.33-7.15-1.48-10.2.73z"
        fill="#f5eddd"
      />
      <path
        d="M108.52 192.06c-3.36 2.44-5 6.73-5.1 10.87s4.28 30.41 8.41 37.87c3.2 5.8 9.87 20.94 20 25.12 3 1.24 47.35.72 67.93-1.51-.13-4.87-.37-9.74-.69-14.61a2.77 2.77 0 00-.55-1.79c-.52-.54-1.26-4.07-2-4.07-12 .05-24.1 3.72-36.1 4a21.12 21.12 0 01-5.21-.31 15 15 0 01-4-1.64c-9.26-5.24-14.38-15.53-18-25.54-2.77-7.72-5-15.71-9-22.92-1.42-2.57-2.69-5.07-5.49-6.2-3.29-1.33-7.15-1.48-10.2.73z"
        transform="translate(-4.89 -12.78)"
        fill="url(#prefix__c)"
      />
      <path
        d="M193.11 230.22c6.23-.59 13.15.8 17.1 5.65a3 3 0 01.78 1.6 2.68 2.68 0 01-.48 1.51c-2.33 3.87-7.39 4.79-11.64 6.32a30.22 30.22 0 00-6.29 3.12 10 10 0 01-3.76 1.86c-3 .45-5.49-2.23-6.85-4.91-1.56-3-4-9.16-1-12 2.45-2.25 9.06-2.87 12.14-3.15zM244.7 228.68a8.53 8.53 0 012.79 2.55 3.67 3.67 0 01.26 3.63 5.05 5.05 0 01-1.87 1.68c-5.53 3.29-12.31 3.88-17.91 7a9.73 9.73 0 01-3.86 1.68 5.33 5.33 0 01-4.28-2 13.6 13.6 0 01-2.31-4.27c-6.55-17.2 17.76-16.27 27.18-10.27z"
        fill="#ffdad2"
      />
      <path
        d="M86.23 221h25.91v21.28a7.5 7.5 0 01-7.5 7.5H93.73a7.5 7.5 0 01-7.5-7.5V221z"
        fill="#fff"
      />
      <path
        d="M110.39 226.16h7.85a6.25 6.25 0 016.25 6.25V235a6.25 6.25 0 01-6.25 6.25h-7.85 0v-15.09h0z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={3.3}
      />
      <rect
        x={45.12}
        y={249.32}
        width={362.1}
        height={11.11}
        rx={5.56}
        fill="#f5eddd"
      />
      <path fill="#f5eddd" d="M138.75 260.6h198.39v68.81H138.75z" />
      <path fill="#f5eddd" d="M62.17 260.6h77.93v68.81H62.17z" />
      <path fill="url(#prefix__d)" d="M140.06 260.6h197.09v68.81H140.06z" />
      <path fill="url(#prefix__e)" d="M62.17 260.6h77.93v68.81H62.17z" />
      <path
        d="M406.6 21.27v43.8H36.21v-43.8a9 9 0 018.95-9h352.49a9 9 0 018.95 9z"
        fill="#f5eddd"
        opacity={0.18}
        style={{
          isolation: 'isolate',
        }}
      />
      <rect
        x={194.46}
        y={248.88}
        width={141.48}
        height={13.04}
        rx={6.52}
        transform="rotate(-180 262.75 249.005)"
        fill="#f5eddd"
      />
      <rect
        x={194.46}
        y={248.88}
        width={141.48}
        height={13.04}
        rx={6.52}
        transform="rotate(-180 262.75 249.005)"
        fill="url(#prefix__f)"
      />
      <path
        d="M240.62 249.15h98.57l26-65.93a5.8 5.8 0 00-5.39-7.92h-77.35a18.68 18.68 0 00-17.34 11.82z"
        fill="#f5eddd"
      />
      <path
        d="M245.63 249.22h93.62l24.69-62.63a5.5 5.5 0 00-5.12-7.52h-73.46a17.73 17.73 0 00-16.5 11.22z"
        fill="#f5eddd"
      />
      <path
        d="M250.52 262h93.62l24.69-62.63a5.5 5.5 0 00-5.12-7.52h-73.46a17.73 17.73 0 00-16.5 11.22z"
        transform="translate(-4.89 -12.78)"
        fill="url(#prefix__g)"
      />
      <path fill="#fff" opacity={0.51} d="M213.02 154.61h138.6v7.8h-138.6z" />
      <path
        fill="#fff"
        d="M213.02 141.26h98.53v7.8h-98.53zM325.02 141.26h65.96v7.8h-65.96zM289.34 91.88h101.41v6.93H289.34z"
      />
      <path fill="#fff" opacity={0.51} d="M268.1 104.19h108.49v6.93H268.1z" />
      <path
        fill="#fff"
        d="M214.9 91.88h66.7v6.93h-66.7zM244.16 116.13h146.59v6.93H244.16z"
      />
      <path
        fill="#fff"
        opacity={0.51}
        d="M214.9 116.13h21.52v6.93H214.9zM293.99 128.44h96.76v6.93h-96.76z"
      />
      <path
        fill="#fff"
        d="M214.9 128.44h66.7v6.93h-66.7zM214.9 103.9h42.89v6.93H214.9zM252.25 68.55h101.41v6.93H252.25z"
      />
      <path fill="#fff" opacity={0.51} d="M231.01 80.86H339.5v6.93H231.01z" />
      <path
        fill="#fff"
        d="M177.82 68.55h66.7v6.93h-66.7zM251.35 54.2h101.41v6.93H251.35zM176.92 41.18h66.7v6.93h-66.7zM176.92 29.3h66.7v6.93h-66.7zM177.82 80.57h42.89v6.93h-42.89z"
      />
    </svg>
  </Fragment>
);

export default NoPostsIllustration;
