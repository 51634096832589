import React, { Fragment, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { MessagesInAlert } from '../Messages';
import NoPosts from '../illustrations/NoPosts';
import { useBlogPosts } from '../../hooks';
import _ from '../../utils';
import styles from './BlogPosts.module.scss';

const BlogPosts = () => {
  const postsData = useBlogPosts();
  const [searchValue, setSearchValue] = useState('');

  const filteredPosts = postsData.filter((post) => {
    if (!postsData.length) return null;
    if (!searchValue) return postsData;

    const { title, description } = post.node.frontmatter;

    // Check both the title & description for the search value.
    return (
      _.includes(_.lowercase(title), _.lowercase(searchValue)) ||
      _.includes(_.lowercase(description), _.lowercase(searchValue))
    );
  });

  if (!postsData.length) {
    return <NoPosts />;
  }

  const allPosts = filteredPosts.map((post) => {
    const {
      node: {
        timeToRead,
        fields: { slug },
        frontmatter: {
          title,
          date,
          description,
          front_image: {
            childImageSharp: { gatsbyImageData },
          },
        },
      },
    } = post;

    return (
      <Fragment key={title}>
        <Link
          to={`/blog/${slug}/`}
          id={`${slug}`}
          aria-label={title}
          role={'article'}
        >
          <Post className={styles.post}>
            <PostCoverImage
              alt={title}
              fluid={gatsbyImageData}
              className={styles.coverImage}
            />
            <PostHeader title={title} textStyles={styles.title} />
            <PostDescription
              description={description}
              className={styles.description}
            />
            <PostDetails
              date={date}
              timeToRead={timeToRead}
              className={styles.details}
            />
          </Post>
        </Link>
      </Fragment>
    );
  });

  return (
    <div className={styles.container}>
      <input
        type="search"
        title="Search"
        name="search-posts"
        autoCapitalize="off"
        autoComplete="off"
        aria-label="Search posts"
        aria-autocomplete="list"
        spellCheck="false"
        placeholder="Search posts"
        className={styles.searchBox}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      {searchValue && !filteredPosts.length ? (
        <NoPostsFoundIllustration />
      ) : (
        allPosts
      )}
      <MessagesInAlert />
    </div>
  );
};

const Post = ({ children, ...props }) => (
  <article {...props}>{children}</article>
);

const PostCoverImage = ({ alt, fluid, ...props }) => (
  <GatsbyImage
    alt={alt}
    image={fluid}
    imgStyle={{ borderRadius: '.25rem' }}
    {...props}
  />
);

const PostDescription = ({ description, ...props }) => (
  <p {...props}>{description}</p>
);

const PostHeader = ({ title, textStyles, ...props }) => (
  <header key={`post-header-${title}`} {...props}>
    <h2 className={textStyles}>{title}</h2>
  </header>
);

const PostDetails = ({ date, timeToRead, ...props }) => (
  <small {...props}>
    {date} · {`${timeToRead} ${_.inflect('min', 'mins', timeToRead)} read`}
  </small>
);

const NoPostsFoundIllustration = () => {
  const {
    file: { publicURL: noSearchResultsIllustration },
  } = useStaticQuery(graphql`
    query post_search_illustration {
      file(name: { eq: "empty-box" }) {
        publicURL
      }
    }
  `);

  return (
    <div className={styles.noSearchResultsContainer}>
      <img
        className={styles.noSearchResultsIllustration}
        src={noSearchResultsIllustration}
        alt="No posts found."
      />
      <h1 className={styles.noSearchResultsMessage}>No posts found.</h1>
    </div>
  );
};

export default BlogPosts;
