import React from 'react';
import Layout from '../components/layout';
import Nav from '../components/Nav';
import BlogPosts from '../components/BlogPosts';
import page from '../HOCs/page';

const Blog = () => {
  return (
    <Layout>
      <Nav currentLocation="Blog" returnLocation="home" />
      <BlogPosts />
    </Layout>
  );
};

export default page(Blog, 'blog');
